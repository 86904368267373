import { colors } from '../../../../themes/variables';
import { TButtonSize } from '../../../../types';

export const getThemeColor = (theme?: string) => {
  switch (theme) {
    case 'white':
      return colors.white;
    case 'blueCrush':
      return colors.blueCrush;
    case 'blueCrush80':
      return colors.blueCrush80;
    case 'mint':
      return colors.mint;
    case 'space':
      return colors.space;
    case 'space40':
      return colors.space40;
    case 'space60':
      return colors.space60;
    case 'space75':
      return colors.space75;
    case 'amber':
      return colors.amber;
    case 'white':
      return colors.white;
    case 'link':
      return colors.rose;
    case 'disabled':
      return colors.space08;
    case 'indigoCrush':
      return colors.indigoCrush;
    default:
      return colors.space;
  }
};

export const getThemeSize = (size?: TButtonSize) => {
  switch (size) {
    case 'big':
      return '60px';
    default:
      return '48px';
  }
};
